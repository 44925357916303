import React from 'react';
import LeftColContent from 'src/components/mobile/textAnimation/textAnimation';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import { useStaticQuery, graphql } from 'gatsby';
import { BlockContainer, ChpaterTitle } from '../../_styles';

const Slide = ({ query, title }) => {
  const modal = useStaticQuery(graphql`
    query{
      file(relativePath: { eq: "chapter_1/sub_2/1-modal1.jpg"}) {
        childImageSharp {
          fixed(width: 506, height: 762)  {
            ...GatsbyImageSharpFixed_withWebp_noBase64
            width
          }
        }
      }
    }
  `);
  return (
    <BlockContainer height="750px" bg="rgba(0,0,0,0.4)">
      {/* <BackgroundAnimation> */}
      <BackgroundPhoto fixed={query.file.childImageSharp.fixed} scale alt="slide-photo">
        <ChpaterTitle marginTop="9rem">{title}</ChpaterTitle>
        <LeftColContent body={query.mdx.body} images={[modal.file.childImageSharp.fixed]} containerStyle={{ height: '70%' }} />
      </BackgroundPhoto>
      {/* </BackgroundAnimation>  */}
    </BlockContainer>
  );
};


export default Slide;
