import React from 'react';
import { useInView } from 'react-intersection-observer';
import { PhotoWrapper, WrapperBackground } from './styles';

const BackgroundPhoto = ({
  fixed, children, alt, scale,
}) => {
  const [ref, inView] = useInView({
    threshold: 0,
  });
  return (
    <PhotoWrapper ref={ref}>
      <WrapperBackground visible={inView && scale} fixed={fixed} alt={alt} />
      {children}
    </PhotoWrapper>
  );
};

export default BackgroundPhoto;
