import React, { useEffect, createRef } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { useInView } from 'react-intersection-observer';
import lottie from 'lottie-web';
import { getLangFromPath } from 'src/utils/index.js'

import { Container, Wrapper, Title, LottieWrapper } from './styles';



const BodyMovinAnimation = ({ fileName, body, delay = 500 }) => {
  const language = getLangFromPath();

  const [ref, inView] = useInView({
    threshold: 0.8,
  });

  const animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: require(`./${fileName}_${language}.json`),
    });
    setTimeout(() => { anim.play(); }, 750);
    return () => anim.destroy(); // optional clean up for unmounting
  }, [inView]);




  return (
    <Container ref={ref} >
      <Wrapper >
        {body ? (
          <Title visible={inView}>
            <MDXProvider components={{
              h1: props => <h1 {...props} tabIndex="0" />,
              h2: props => <h2 {...props} tabIndex="0" />,
              h3: props => <h3 {...props} tabIndex="0" />,
              h4: props => <h4 {...props} tabIndex="0" />,
              p: props => <p {...props} tabIndex="0" />
            }}>
              <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
          </Title>
        ) : null}
        <LottieWrapper visible={inView} ref={animationContainer} />
      </Wrapper>
    </Container>
  );
};

export default BodyMovinAnimation;
