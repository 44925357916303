import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import BackgroundPhoto from 'src/components/desktop/backgroundPhoto/backgroundPhoto';
import Animation from 'src/components/desktop/bodyMovinAnimation/BodyMovinAnimation';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { NoiseOverlay } from 'src/components/desktop/noiseOverlay/noiseOverlay';
import { SlideLarge, FlexWrapper } from '../../_styles';

// import BackgroundAnimation from '../../../../components/desktop/backgroundAnimation/backgroundAnimation.jsx';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    background: file(relativePath: { eq: "chapter_1/sub_2/1.png"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    modal1: file(relativePath: { eq: "chapter_1/sub_2/1-modal1.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp
        width
      }
    }
  },
  modal2: file(relativePath: { eq: "chapter_1/sub_2/1-modal2.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp
        width
      }
    }
  }
  }
`);

  // TODO: w rosyjskiej wersji brakuje końcówki tłumaczenia dla modala marksizmu

  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ color: 'rgba(19,35,46, 0.25)' }}
        >
          <BackgroundPhoto fixed={assetQuery.background.childImageSharp.fixed} scale alt="slide-photo" />

        </Mordo>
        <NoiseOverlay scene={1} />
        <Mordo
          flexi
          scene={2}
          move={{ x: 10, y: 20 }}
        >
          <FlexWrapper>
            <LeftColContent body={query.leftColumn.body} images={[assetQuery.modal1.childImageSharp.fixed, assetQuery.modal2.childImageSharp.fixed]} />
            <Animation
              fileName="flue_war_casulties"
              body={query.animation.body}
              delay={500}
            />
          </FlexWrapper>
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>
  );
};

export default Slide;
