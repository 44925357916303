import styled, { css } from 'styled-components';

const ANIMATION_START = css`
  transform: translate3d(0,35px,0);
  opacity: 0;
`;
const ANIMATION_END = css`
  opacity: 1;
  transform: translate3d(0,0,0);
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.div`
  h2 {
    font-family: "Abril Fatface";
    font-size: 1.37rem;
    letter-spacing: 0.0028rem;
    font-weight: 400;
    color: #f9f8f3;
    position: relative;
    ${ANIMATION_START}
    transition: opacity 0.8s cubic-bezier(0.82, 0, 0.195, 1) 0.5s, 
    transform 0.8s cubic-bezier(0.820, 0.005, 0.180, 1.000) 0.5s;

    ${({ visible }) => visible
    && css`
        ${ANIMATION_END}
      `};
  }
`;

export const LottieWrapper = styled.div`
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.82, 0, 0.195, 1);
  ${({ visible }) => visible
    && css`
      opacity: 1;
    `};
`;
