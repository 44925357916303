import React from 'react';
import SliderContent from 'src/components/mobile/sliderContent/sliderContent';
import SubSlide3 from './subSlide3';
import SubSlide4 from './subSlide4';
import SubSlide5 from './subSlide5';
import SubSlide6 from './subSlide6';
import SubSlide7 from './subSlide7';
import SubSlide8 from './subSlide8';
import { BlockContainer } from '../../../_templates/_styles';

const WrapperSubSlider = () => (
  <BlockContainer style={{ background: '#101e29', padding: '2rem 0' }}>
    <SliderContent>
      <SubSlide3 />
      <SubSlide4 />
      <SubSlide5 />
      <SubSlide6 />
      <SubSlide7 />
      <SubSlide8 />
    </SliderContent>
  </BlockContainer>
);

export default WrapperSubSlider;
