import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import BackgroundPhoto from 'src/components/desktop/backgroundPhoto/backgroundPhoto';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { Slide as SlideCustom } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "chapter_1/sub_2/8.jpg" }) {
      childImageSharp {
        fixed(width: 583) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
          width
        }
      }
    }
  }
`);
  return (
    <SlideCustom width={39}>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: 0, y: 0 }}
          background={{ color: 'rgba(19,35,46, 0.25)' }}
        >
          <BackgroundPhoto fixed={assetQuery.file.childImageSharp.fixed} alt={query.mdx.frontmatter.alt} />
        </Mordo>
        <Mordo customStyle={{ width: '100%' }}>
          <LeftColContent body={query.mdx.body} style={{ paddingLeft: '15%' }} />
        </Mordo>

      </ParallaxMordo>
    </SlideCustom>
  );
};

export default Slide;
