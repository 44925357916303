import styled, { keyframes, css } from 'styled-components';
import Img from 'gatsby-image';


const enter = keyframes`
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(1.1);
    }
`;

const enterAnimation = ({ visible }) => {
  if (visible) {
    return css`
      animation: ${enter} 0.8s cubic-bezier(0.25, 0.25, 0.6, 1) forwards;
    `;
  }
  return null;
};


export const PhotoWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const WrapperBackground = styled(Img)`
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    ${enterAnimation}
`;
