import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import Animation from 'src/components/mobile/bodyMovinAnimation/BodyMovinAnimation';
import { BlockContainer } from '../../_styles';

const Slide = ({ query }) => (
  <BlockContainer height="610px">
    {/* <BackgroundAnimation> */}
    <BackgroundPhoto
      fixed={query.file.childImageSharp.fixed}
      enter
      alt="slide-photo"
      mask="rgba(21,27,37,0.6)"
    >
      <Animation
        fileName="flue_war_casulties"
        body={query.animation.body}
        delay={500}
      />
    </BackgroundPhoto>
    {/* </BackgroundAnimation>  */}
  </BlockContainer>
);

export default Slide;
