import styled, { css } from 'styled-components';
import { fonts, colors } from '../../../../../globals';

const ANIMATION_START = css`
  transform: translate3d(0,35px,0);
  opacity: 0;
`;
const ANIMATION_END = css`
  opacity: 1;
  transform: translate3d(0,0,0);
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10%;
`;

export const Wrapper = styled.div`
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.div`
    h2 {
      ${{ ...fonts.headline_3a }}
      ${{ ...colors.light }}
      position: relative;
    }

    ${ANIMATION_START}
    transition: opacity 0.8s cubic-bezier(0.82, 0, 0.195, 1) 0.5s, 
    transform 0.8s cubic-bezier(0.820, 0.005, 0.180, 1.000) 0.5s;
    ${({ visible }) => visible
    && css`
        ${ANIMATION_END}
      `};
`;

export const LottieWrapper = styled.div`
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.82, 0, 0.195, 1);
  ${({ visible }) => visible
    && css`
      opacity: 1;
    `};
`;
