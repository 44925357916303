import React, { useEffect, createRef } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import lottie from 'lottie-web';

import { useInView } from 'react-intersection-observer';
import { getLangFromPath } from 'src/utils/index.js'

import { Container, Wrapper, Title, LottieWrapper } from './styles';

const BodyMovinAnimation = ({ fileName, body, delay = 500 }) => {
  const language = getLangFromPath();

  const [ref, inView] = useInView({
    threshold: 0.6,
  });

  const animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: require(`./${fileName}_${language}.json`),
    });
    setTimeout(() => { anim.play(); }, 500);
    return () => anim.destroy(); // optional clean up for unmounting
  }, [inView]);


  const defaultOptions = {
    loop: false,
    autoplay: true,
    renderer: 'svg',
    animationData: require(`./${fileName}_${language}.json`),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container >
      <Wrapper ref={ref}>
        {body ? (
          <Title visible={inView}>
            <MDXProvider components={{
              p: (props) => <p {...props} tabIndex="0" />,
            }}
            >
              <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
          </Title>
        ) : null}
        <LottieWrapper visible={inView} ref={animationContainer} />
      </Wrapper>
    </Container>
  );
};

export default BodyMovinAnimation;
